$(document).ready(function()
{
  /* show alert if session expired */

  $('.bg-alert__session-expired').hide();
  if (window.location.hash === '#session_expired') {
    $('.bg-alert__session-expired').show();
  }

  function showLogin() {
      bg_utils.show('.page__loginWrap');
      bg_utils.scrollToElement('.page_loginWrap');
      $('#loginFormNameF').focus();
  }

  $('#showLogin').click(function(){
     showLogin();
  });
});
